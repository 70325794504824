<template>
    <div class="inner-container">
        <div class="section-title budget"><i class="icon icon-calculator-coins"></i><span>{{ getCommonText('budget') }}</span></div>
        <div class="section-subtitle"><i class="icon icon-grid"></i><span>{{ $t("app.links.budget_requests") }}<budget-forms-list :curFormSelect="'/form01-123'"/></span>
            <forms-download-reprt
                :progress="progress"
                :isReportUploading="isReportUploading"
                :form="form"
                :guListLen="guListLen"
                :header="header"
                :isParentGuExist="isParentGuExist"
                :isHeadGu="isHeadGu"
                @checkSignatories="checkSignatories"
                @downloadRep="downloadRep"
                @downloadBatchReports="downloadBatchReports"
                @downloadBatchReportsPaid="downloadBatchReportsPaid"
            ></forms-download-reprt>
        </div>
        <div class="filter-container">
            <div class="left-content">
                <budget-header ref="budgetHeader" :form="form" @chgData="changeHeader" :yearReq="true" :openDisabled="openDisabled" @setProgress="setProgress"></budget-header>
            </div>
            <div class="right-content">
                <div class="filter-actions filter-actions-flex">
                    <c-budget-forms-copy-data
                        v-if="!(progress < 100) && variantAttribute && (header.year < header.cur_year + 2)"
                        :budgetForm="budgetForm"
                        :header="header"
                        :isLoad="isLoad"
                        @keyPress="keyPress"
                    />
                    <b-button :style="{ 'minWidth': '110px' }" variant="primary" @click="onAddNewCat" v-if="variantAttribute" :disabled="isLoad"><i class="icon icon-plus-circle"></i> {{ getCommonText('add') }}</b-button>
                    <b-button :style="{ 'minWidth': '110px' }" variant="success" @click="prepareForSave" :disabled="isLoad || !variantAttribute">{{ getCommonText('save') }}</b-button>
                </div>
            </div>
        </div>
        <breadcrumbs-filter :header="{...header, formName: this.formName, spfName: this.spfName}" :data-type-filter="dataTypeFilter"
                            :variant-name="variantName" @openFilterByRef="openFilterByRef"/>
         <b-progress
                variant="success"
                v-show="progress < 100 && progress > 0"
                height="10px"
                :value="progress"
                striped
                animated
        ></b-progress>

        <div class="table-container">
            <b-table
                :fields="tableFields"
                :items="budgetForm"
                responsive="true"
                bordered
                head-variant="light"
                no-border-collapse
                sticky-header="true"
            >
                <template #top-row="data">
                    <td class="td-numbering"></td>
                    <td class="td-numbering table-success">1</td>
                    <td class="td-numbering table-danger">2</td>
                    <td class="td-numbering table-info">3</td>
                    <td class="td-numbering table-primary">4</td>
                    <td class="td-numbering table-warning">5</td>
                    <td class="td-numbering"></td>
                </template>
                <template #head(action)>
                    <div class="text-center">
                        <b-form-checkbox
                            v-model="selectAll"
                            @change="e => setIsAllDelete(e)"
                            :value="true"
                            :unchecked-value="false" />
                    </div>
                </template>
                <template #head(more)>
                    <div class="text-center">
                        <i
                            :title="getCommonText('del_selected')"
                            class="icon icon-close table-all-remove"
                            @click="deleteItemWithAttachedFiles(`${selectAll ? getCommonText('clear_all') : getCommonText('clear')}`)"/>
                    </div>
                </template>
                <template #cell(action)="data">
                    <b-form-checkbox
                        v-model="data.item.itemToDelete"
                        @input="e => { if (!e) selectAll = false; }"
                        :value="true"
                        :unchecked-value="false" />
                </template>
                <template #cell(name)="data">
                    <div
                    class="column-width-100 bp-category-name">
                        <div>{{ data.item['name_' + lng] }} </div>
                        <i 
                            v-if="variantAttribute"
                            class="icon icon-pencil-edit thin-blue ml-3 pointer icon-18" 
                            @click="onEditCatName(data.item)"
                        ></i>
                    </div>
                </template>
                <template #cell(summa)="data">
                    <b-form-input v-if="variantAttribute"
                                  class="text-right"
                                  :value="data.item.summa"
                                  @change="v => data.item.summa = v"
                                  @keyup.enter.exact="keyup13"
                                  @keypress="keyPress($event, '^\\d*\\.?\\d{0,9}$')"
                                  @blur="inputFixed(data.item, 'summa', data.item.summa, 2)">
                    </b-form-input>
                    <div v-else>{{ data.item.summa }}</div>
                    <p class="goods-form-val-msg" v-if="variantAttribute && (data.item.summa === null || data.item.summa === '' || data.item.summa <= 0)">{{ getCommonText("positive") }}</p>
                </template>
                <template #cell(term)="data">
                    <b-form-input v-if="variantAttribute"
                                  class="text-right"
                                  :value="data.item.term"
                                  @change="v => data.item.term = v"
                                  @keyup.enter.exact="keyup13"
                                  @keypress="keyPress($event, '^[0-9]+$')"
                                  @blur="inputFixed(data.item, 'term', data.item.term, 0)">
                    </b-form-input>
                    <div v-else>{{ data.item.term }}</div>
                    <p class="goods-form-val-msg" v-if="variantAttribute && (data.item.term === null || data.item.term === '' || data.item.term <= 0)">{{ getCommonText("positive") }}</p>
                </template>
                <template #cell()="data">
                    <div class="text-right">{{ $n(data.value) }}</div>
                </template>
                <template #cell(files)="data">
                    <span
                        class="blue pointer underline"
                        @click="onFilesClick(data.item)"
                    >({{data.item.files}})</span>
                </template>
                <template #cell(more)="data">
                    <div class="text-center">
                        <i 
                            :title="getCommonText('del_rec')" 
                            class="icon icon-clear table-remove" 
                            v-if="variantAttribute" 
                            @click="deleteItemWithAttachedFiles(`${getCommonText('del_rec')}?`, data.item, data.index)"
                        ></i>
                    </div>
                </template>
                <template #bottom-row="data">
                    <td class="text-right" colspan="4">{{ getCommonText('total') }}</td>
                    <td class="text-right">{{ $n(total) }}</td>
                    <td colspan="2"></td>
                </template>
            </b-table>
        </div>
        <div class="table-add" v-if="variantAttribute" :disabled="isLoad">
            <span @click="onAddNewCat"><i class="icon icon-plus-circle"></i> {{ getCommonText('add') }}</span>
        </div>
        <files-updown :header="header"
                      :variant-attribute="variantAttribute"
                      :load="load"
                      @getFiles="getFiles"
                      @getNewFiles="getNewFiles($event)"
                      @delFile="delFile($event)"
                      ref="fileUpdown"
        ></files-updown>
        <modal-files-management-nodecode
            ref="modalFilesManagement"
            :variant-attribute="variantAttribute"
            @triggDownloadFile="triggDownloadFile"
            @triggDownloadAllFiles="triggDownloadAllFiles"
            @fileUpload="fileUpload"
            @toggleIsAdd="toggleIsAdd($event)"
        />
        <c-set-b-f-category-name
            ref="setCatNameModal"
            :budgetForm="budgetForm"
            :categoryFieldNames="categoryFieldNames"
            :lng="lng"
            :getCommonText="getCommonText"
            @addItem="addItem"
        />
    </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import BudgetHeader from '@/modules/budget-request/budget-header-new.vue';
import FilesUpdown from '@/modules/budget-request/FilesUpdown';
import BudgetFormsList from '@/modules/budget-request/components/budget-forms-list.vue';
import BreadcrumbsFilter from '@/modules/budget-request/components/breadcrumbs-filter.vue';
import { Ax } from '@/utils';
import ModalFilesManagementNodecode from './components/modal-files-management-nodecode.vue';
import FormsHandlerMixin1 from "./mixins/forms-handler-mixin-1";
import CBudgetFormsCopyData from './components/budget-forms-copy-data.vue';
import FormsDownloadReprt from "./components/forms-download-reprt.vue";
import CSetBFCategoryName from "./components/c-set-bf-category-name.vue";

export default {
    name: 'Form01_139',
    components: { 
        BudgetHeader, 
        FilesUpdown, 
        BudgetFormsList, 
        FormsDownloadReprt, 
        BreadcrumbsFilter, 
        ModalFilesManagementNodecode, 
        CBudgetFormsCopyData,
        CSetBFCategoryName 
    },
    mixins: [FormsHandlerMixin1],
    data() {
        return {
            form: {
                code: '01-139',
                name_ru: 'РАСЧЕТ РАСХОДОВ НА ОПЛАТУ ТРУДА ИНОСТРАННЫХ РАБОТНИКОВ ГОСУДАРСТВЕННЫХ ОРГАНОВ',
                name_kk: 'МЕМЛЕКЕТТІК ОРГАНДАРДЫҢ ШЕТЕЛДІК ҚЫЗМЕТКЕРЛЕРІНІҢ ЕҢБЕК ШЫҒЫНЫН ЕСЕПТЕУ'
            },
            defaultParams: {
                dataType: '1',
                gr: 1,
                abp: 700,
                prg: 1
            },
            dataTypeFilter: null,
            variantAttribute: null,
            variantName: null,
            budgetForm: [],
            header: null,
            files: null,
            load: false,
            openDisabled: false,
            isLoad: false,
            row_files: [],
            isAdd: false,
            rowId: false,
            newRowStartId: -1,
            categoryFieldNames: {ru: "name_ru", kk: "name_kk"}
        };
    },
    methods: {
        addItem(nameRu, nameKk) {
            const that = this;
            const item = { 
                id: this.newRowStartId,
                name_ru: nameRu,
                name_kk: nameKk
            };
            this.newRowStartId--;
            that.itemUpdate(item);
            Object.defineProperty(item, 'total', {
                get: function () {
                    const total = that.number(item.summa) * that.number(item.term);
                    return parseFloat((Math.round(total * 100) / 100).toFixed(2));
                }
            });

            that.budgetForm.push(item);
        },

        downloadRep() {
            this.isReportUploading = true;
            this.header.lang = this.$i18n.locale;
            this.header.code_modules = this.moduleCode;
            Ax(
                {
                    url: '/api-py/budg_' + this.form.code.replace('-', '_') + '/' + JSON.stringify(this.header),
                    method: 'POST',
                    responseType: 'blob'
                },
                (data) => {
                    const url = window.URL.createObjectURL(new Blob([data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `${this.getCommonText('form')} ` + this.form.code.replace('-', '_') + '.xls');// or any other extension
                    document.body.appendChild(link);
                    link.click();
                },
                (error) => {
                    this.makeToast('danger', `${this.getErrText('bad_request')} downloadRep()`, error.toString());
                }
            );
        },

        inputFixed(item, field, value, digit, max = 0) {
            // item - строка
            // field - наименование поля
            // value - глвле значение
            // digit - количество цифр после запятой
            // max - максимальное значение
            this.inputFixedVldtn(item, field, value, digit);
            if ((max > 0) && (value > max)) {
                this.$set(item, field, 0);
                this.makeToast('danger', this.getErrText('msg'), this.getCommonText('form', max));
            }
        }, // форматирует введенное значение до digit цифр после запятой

         async itemUpdate(item) {
            this.$set(item, 'summa', 0);
            this.$set(item, 'term', 0);
            this.$set(item, 'itemToDelete', false);
            this.$set(item, 'files', 0);

            try {
                this.load = true;
                const response = await fetch(`/api-py/get-new-row-files-form/${item.id}/` + JSON.stringify(this.header));
                const emptyRowFiles = await response.json();
                this.$set(item, 'row_files', emptyRowFiles);
            } catch (error) {
                this.makeToast('danger', `${this.getErrText('bad_request')} itemUpdate`, error.toString());
            }
            this.load = false;
        },

        number(value) {
            return (isNaN(parseFloat(value)) ? 0 : parseFloat(value));
        },

        getFiles(data) {
            this.files = data;
        },

        keyPress: function (event, pattern) {
            // const regex = new RegExp('^[0-9]+$'); (^ и $ отмечают начало и конец строки соответственно)
            // ^[a-zA-Z]+$ - соответствует только строкам, которые состоят только из одной или нескольких букв
            // ^[0-9]+$ - ввод только цифр
            // ^-?\\d*\\d{0,9}$ - ввод отрицательных и положительных чисел
            // ^-?\\d*\\.?\\d{0,9}$ - ввод чисел с плавающей точкой
            const regex = new RegExp(pattern);
            const key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
            if (!regex.test(key)) {
                event.preventDefault();
                return false;
            }
        }, // вводит по заданному паттерну

        keyup13: function (event) {
            event.preventDefault();
            // Isolate the node that we're after
            const currentNode = event.target;
            // find all tab-able elements
            const allElements = document.querySelectorAll('input'); // area, object, select, [contenteditable]
            // Find the current tab index.
            const currentIndex = [...allElements].findIndex(el => currentNode.isEqualNode(el));
            // select/focus the following element
            const targetIndex = (currentIndex + 1) % allElements.length;
            if (targetIndex < allElements.length) {
                allElements[targetIndex].select();
            }
        }, // enter работает как tab

        async loadDatas() {
            const that = this;
            this.load = true;
            await this.budgetForm.splice(0);
            try {
                const response = await fetch('/api-py/get-budget-request-form-with-row-attach-files/' + JSON.stringify(this.header));
                const values = await response.json();
                await values.forEach(val => {
                    const item = {
                        id: val.id,
                        name_ru: val.name_ru,
                        name_kk: val.name_kk,
                        summa: val.summa,
                        term: val.term,
                        files: val.files,
                        row_files: val.row_files,
                        itemToDelete: false,
                    };
                    Object.defineProperty(item, 'total', {
                        get: function () {
                            const total = that.number(item.summa) * that.number(item.term);
                            return parseFloat((Math.round(total * 100) / 100).toFixed(2));
                        }
                    });
                    this.budgetForm.push(item);
                });
            } catch (error) {
                this.makeToast('danger', `${this.getErrText('bad_request')} loadDatas`, error.toString());
            }
            this.load = false;
        },

        makeToast(variant, title, tostbody) {
            this.$bvToast.toast(tostbody, {
                title: title,
                variant: variant,
                toaster: 'b-toaster-top-center',
                autoHideDelay: 5000,
                appendToast: true
            });
        }, // сообщение

        prepareForSave() {
            const values = [];
            for (const row of this.budgetForm) {
                if (this.isSavingForbidden(row, this.tableFields, ['summa', 'term'], [])) return;
                const item = Object.assign({}, this.header);
                this.$set(item, 'id', row.id);
                this.$set(item, 'name_ru', row.name_ru);
                this.$set(item, 'name_kk', row.name_kk);
                this.$set(item, 'summa', parseFloat(row.summa));
                this.$set(item, 'term', parseInt(row.term));
                this.$set(item, 'row_files', row.row_files);

                values.push(item);
            }
            if (values.length > 0) {
                this.$bvModal.msgBoxConfirm(
                    this.getCommonText('on_save'),
                    {
                        title: this.getCommonText('confirm'),
                        size: 'lg',
                        buttonSize: 'sm',
                        okVariant: 'success',
                        okTitle: this.getCommonText('yes'),
                        cancelTitle: this.getCommonText('cancel'),
                        footerClass: 'p-2',
                        hideHeaderClose: false,
                        centered: true
                    })
                    .then(value => {
                        if (value && this.variantAttribute) {
                            this.save(values, this.form.code);
                        }
                    })
                    .catch(error => {
                        this.makeToast('danger', this.getErrText('not_saved'), error.toString());
                    });
            } else {
                this.makeToast('warning', this.getErrText('msg'), this.getErrText('not_saved'));
            }
        },

        isDataValid(row) {
            if (row.summa === 0 || row.term === 0) {
                return false;
            } 
            else return true;
        },

        async save(values, numberOfForm) {
            this.isLoad = true;
            try {
                this.$set(this.header, 'value', this.total);
                const url = this.header.mode === 'gkkp' ? '/api-py/save-brform-gkkp/' : '/api-py/save-brform' + this.form.code + '/'
                const response = await fetch(url + JSON.stringify(this.header), {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(values)
                });
                const result = await response.json();
                if ((response.status === 200) && (result.result === 'success')) {
                    if (this.files.length === 0) {
                        this.makeToast('warning', this.getErrText('warning'), this.getErrText('no_docs'));
                    }
                    this.makeToast('success', this.getErrText('msg'), this.getErrText('saved'));
                    this.deletingAgreementTotalResultHandler(result);
                    await this.loadDatas();
                } else {
                    await this.loadDatas();
                    throw this.getErrText('bad_data');
                }
            } catch (e) {
                this.makeToast('danger', this.getErrText('warning'), e.toString());
            } finally {
                this.isLoad = false;
            }
        }, // сохранение данных

        getFormText(field, extra = null) {
            const link = 'modules.budget_request.calculation_forms.table_headers.form_01_139.';
            return this.getText(link, field, extra)
        },

    },
    computed: {
        total() {
            let sum = 0;
            for (const row of this.budgetForm) {
                sum += parseFloat(row.total);
            }
            return Math.ceil(sum);
        },

        tableFields() {
            if (this.$i18n.locale) {
                return [
                    {
                        key: 'action',
                        label: ' '
                    },
                    {
                        key: 'name',
                        label: this.getFormText('pos_name')
                    },
                    {
                        key: 'summa',
                        label: `${this.getFormText('pos_salary')} (${this.getCommonText('thnds_tg')})`
                    },
                    {
                        key: 'term',
                        label: `${this.getFormText('foreign_attr_period')} (${this.getCommonText('months')})`
                    },
                    {
                        key: 'total',
                        label: `${this.getFormText('annual_salary')} (${this.getCommonText('thnds_tg')})`
                    },
                    {
                        key: 'files',
                        label: this.getCommonText('files')
                    },
                    {
                        key: 'more',
                        label: ''
                    }
                ];
            };
            return [];
        }
    }
};
</script>

<style scoped>
    .goods-form-val-msg {
        font-size: 0.5rem;
        font-style: italic;
        color: red;
    }
    .filter-actions-flex {
        display: flex;
    }
    .bp-category-name {
        display: flex;
        justify-content: space-between;
    }
    .bp-category-name .icon-pencil-edit {
        align-self: center;
    }
</style>